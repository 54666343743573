.refresh-container {
}
.refresh-text {
  margin: 0px 0px 16px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
}
.btn-refresh {
  width: 100px;
  height: 40px;
  background-color: #f16022;
  color: #ffffff;
  border-radius: 16px;
  border: none;
  outline: none;
  font-weight: bold;
  cursor: pointer;
}

.container-refresh-with-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 154px);
  width: 100%;
  position: absolute;
  /* top: 0; */
  bottom: 0;
  background-color: #30638e;
  opacity: 0.9;
  z-index: 3;
}

.container-refresh-with-text-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 141px);
  width: 100%;
  position: absolute;
  /* top: 0; */
  bottom: 0;
  background-color: #30638e;
  opacity: 0.9;
  z-index: 3;
}
