.toggle-container {
  display: flex;
  flex-direction: column;
  padding: 24px 24px 23px 24px;
}
.toggle-mobile-container {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 24px 16px;
}
.detail-toggle-display-container {
}
.detail-toggle-display-mobile-container {
  display: flex;
}
.hex-numner-container {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  align-items: center;
  margin: 0px 0px 8px 0px;
  font-weight: bold;
}
.hex-nummer-number {
  margin: 0px 4px 0px 0px;
  font-size: 20px;
}
.hex-nummer-dash {
  margin: 0px 4px 0px 0px;
}
.hex-nummer-digit {
  font-size: 14px;
}
.detail-toggle-container {
  display: flex;
  width: 100%;
}
.toggle-text {
  text-transform: capitalize;
}
.toggle-img {
  margin: 0px 8px 0px 0px;
}
.toggle-information {
  font-weight: normal;
  font-size: 9px;
  line-height: 11px;
  /* color: #ffffff; */
  color: black;
  margin: 4px 0px 0px 0px;
}
.number-hexcode {
  font-size: 20px;
  margin: 0px 4px 0px 0px;
}
.dash-submit {
  margin: 0px 4px 0px 0px;
}
.withhex {
  display: flex;
  align-items: center;
  margin: 0px 0px 4px 0px;
}
.notiam-container {
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  color: black;
}
/* .MuiToggleButton-root {
  background-color: #ffffff;
  border-radius: 8px;
  height: 24px;
}

.MuiToggleButton-root:hover {
  background-color: #ffffff;
  border-radius: 8px;
}

.MuiToggleButtonGroup-root {
  background-color: #30638e;
  border-radius: 8px;
  height: 24px;
} */

.iam-container {
  /* font-style: normal;
  font-weight: bold;
  font-size: 14px; */
  line-height: 24px;
  /* color: #ffffff; */
  color: black;
  padding: 0px 0px 4px 0px;
}
