.joptioncardtwo-container {
  border: 1px solid;
  border-radius: 5px 5px;
  border-style: none none none none;
  padding: 4px 0px;
  background-color: white;
  cursor: pointer;
}
.joptioncardtwo-mobile-container {
  border: 1px solid;
  border-radius: 5px 5px;
  border-style: none none none none;
  padding: 4px 0px;
  background-color: white;
  cursor: pointer;
}

.joptioncardtwo-container:hover .joptioncardone-divider {
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #30638e;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
