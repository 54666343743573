.joverview-detail-multi-container {
  display: flex;
  align-items: center;
}

.joverview-detail-arrow-container {
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
  height: 100%;
  max-height: 34px;
}

.joverview-detail-arrow {
  display: flex;
}
