.appbar-mobile {
  display: flex;
  align-items: center;
  padding: 10px 16px;
}
.appbar-desktop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 10px 24px;
}
.appbar-btn-menu {
  border: none;
  outline: none;
  background-color: transparent;
  color: #30638e;
  font-weight: 600;
  font-size: 14px;
  margin: 0px 24px 0px 0px;
  cursor: pointer;
}

.appbar-btn-menu:focus {
  color: #f16022;
}

.appbar-desktop-menu-container {
  display: flex;
  align-items: center;
  color: black;
}
