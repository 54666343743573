.journey-details-walk-leg-container {
  padding: 16px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 80px;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: '. .';
}
.journey-details-mobile-walk-leg-container {
  padding: 16px 8px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 80px;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: '. .';
}
.journey-details-walk-detail {
  display: flex;
  align-items: center;
}
.journey-details-walk-stop-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px 0px 0px 8px;
}
.walk-to {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin: 0px 4px 0px 0px;
}
.routeid-container {
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 0.5px solid #128110;
  padding: 0px 4px 0px 4px;
  height: 17px;
  margin: 0px 4px 0px 0px;
}
.routeid-container-rail {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0px 4px 0px 4px;
  margin: 0px 4px 0px 0px;
  height: 17px;
}
.stop-name-container {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  margin: 0px 4px 0px 0px;
}
.journey-details-walk-time {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.j-time {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  margin: 0px 4px 0px 0px;
}

.j-measure {
  font-style: normal;
  font-size: 8px;
  line-height: 14px;
  /* align-self: flex-end; */
  margin: 0px;
}
