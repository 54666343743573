.mode-parent-container {
  width: 100%;
}

.mode-parent-text {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  font-size: 14px;
  color: #939598;
}
