.btn-show-map {
  width: 100%;
  height: 34px;
  background-color: #f16022;
  border-radius: 16px;
  border: none;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-show-map:hover {
  width: 100%;
  height: 34px;
  background-color: #f16022;
  border-radius: 16px;
  border: none;
  color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
