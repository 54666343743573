.btn-search {
  width: 136px;
  height: 34px;
  background-color: #939598;
  border-radius: 8px;
  border: none;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
}
.btn-disabled {
  width: 136px;
  height: 34px;
  background-color: #f16022;
  border-radius: 8px;
  border: none;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
}

.btn-disabled:hover {
  width: 136px;
  height: 34px;
  background-color: #f16022;
  border-radius: 8px;
  border: none;
  color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  font-weight: 600;
  cursor: pointer;
}

.btn-search:hover {
  width: 136px;
  height: 34px;
  background-color: #939598;
  border-radius: 8px;
  border: none;
  color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  font-weight: 600;
  cursor: pointer;
}
