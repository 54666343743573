.home-submit-parent {
  padding: 24px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
}
.home-submit-mobile-parent {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 24px 16px 24px 16px;
  display: flex;
  flex-direction: column;
}
