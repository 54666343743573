.jcard-depature-container {
  /* display: flex;
  align-items: center; */
}
.jcard-depature-mode {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  color: #f1af1d;
  line-height: 15px;
}

.jcard-depature-stops {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #939598;
  line-height: 15px;
  align-self: flex-end;
}
