.menu-container {
  display: flex;
  align-items: center;
}
/* 
.hexcode-menu-container {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.hexcode-menu-number {
  margin: 0px 4px 0px 0px;
  font-weight: bold;
}

.dash-submit-menu {
  margin: 0px 4px 0px 0px;
  font-size: 14px;
} */

.mmenu-hexcode-container {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.mmenu-hexcode-number {
  margin: 0px 4px 0px 0px;
  font-size: 20px;
}

.mmenu-dash-submite {
  margin: 0px 4px 0px 0px;
}

.mmenu-hexcode-number-mobile {
  margin: 0px 4px 0px 0px;
  font-weight: bolder;
  font-size: 15px;
}
