.walk-container {
  display: flex;
  align-items: center;
  border: 0.5px solid #bbbbbb;
  padding: 2px 8px;
  box-sizing: border-box;
  border-radius: 4px;
}

.joption-car-container {
  display: flex;
  align-items: center;
}

.joption-dropoff-container {
  display: flex;
  align-items: center;
}

.joption-train-container {
  display: flex;
  align-items: center;
}

.joption-bus-container {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
}
.joption-park-container {
  display: flex;
  align-items: center;
}

.speed-container {
  font-size: 8px;
  position: relative;
  top: 3px;
}

.speed-walk-container {
  font-size: 8px;
  position: relative;
  top: 6px;
}

.joption-text-container {
  display: flex;
  justify-content: center;
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0px 4px 0px 4px;
  height: 17px;
}

.joption-bus {
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 0.5px solid #128110;
  padding: 0px 4px 0px 4px;
  height: 17px;
}
.joption-arrow {
  display: flex;
  align-self: flex-start;
}
.joption-arrow-container {
  display: flex;
  flex-wrap: wrap;
  height: 42px;
}
