.sidebar-mobile-container {
  /* background-color: #30638e; */
  background-color: white;
  padding: 44px 0px 32px 0px;
}

.sidebar-desktop-container {
  position: relative;
  top: 44px;
  /* background-color: #30638e; */
  background-color: white;
  width: 420px;
  /* padding: 44px 0px 0px 0px; */
}

.sidebar-disclaimer-container {
  padding: 0px 16px 16px 16px;
  font-size: 8px;
  position: absolute;
  bottom: 0;
  text-align: justify;
}

.sidebar-disclaimer-title {
  font-weight: 700;
  margin: 0px 0px 4px 0px;
}

.sidebar-desktop-children {
  display: flex;
  flex-direction: column;
}
