.joverview-detailst-timeduration-container {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  width: min-content;
  border-radius: 4px;
  justify-content: flex-end;
}

.joverview-detailst-timeduration-moreoption {
  display: flex;
  align-items: center;
  margin: 0px 2px 0px 0px;
}

.joverview-primary {
  font-size: 14px;
  font-weight: bold;
  align-self: flex-end;
  margin: 0px 4px 0px 0px;
  line-height: 14px;
}

.joverview-secondary {
  font-size: 10px;
  align-self: flex-end;
  line-height: 11px;
}
