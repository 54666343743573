.autocomplete-parent-container {
  padding: 0px 24px 0px 24px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 24px;
  grid-template-rows: 1fr;
  gap: 8px 4px;
  grid-template-areas: '. .';
  align-items: center;
}

.autocomplete-parent-text {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  /* color: #ffffff; */
  color: black;
  padding: 0px 24px 4px 24px;
}

.hex-number-autocomplete {
  font-size: 20px;
  margin: 0px 4px 0px 0px;
}

.hex-number-autocomplete-container {
  display: flex;
  margin: 0px 0px 4px 0px;
}

.dash-submit-autocomplete {
  margin: 0px 4px 0px 0px;
}

.autocomplete-parent-mobile-text {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  /* color: #ffffff; */
  color: black;
  padding: 0px 16px 4px 16px;
}

.autocomplete-parent-mobile-container {
  padding: 0px 16px 0px 16px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 24px;
  grid-template-rows: 1fr;
  gap: 8px 4px;
  grid-template-areas: '. .';
  align-items: center;
}
