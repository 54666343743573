.journeyaddress-parent-desktop-container {
  /* background-color: #30638e; */
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 20px 24px;
  /* display: flex; */
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 24px minmax(0, 1fr);
  grid-template-rows: 1fr;
  gap: 0px 8px;
  grid-template-areas: '. .';
}

.joureyaddress-parent-mobile-container {
  /* background-color: #30638e; */
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 10px 16px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 24px minmax(0, 1fr);
  grid-template-rows: 1fr;
  gap: 0px 8px;
  grid-template-areas: '. .';
}
