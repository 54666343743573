.btn-preference {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #f1af1d;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.preference-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
