.joption-mode-duration-container {
  padding: 0px 16px;
}
.joption-mode-duration-mobile-container {
  padding: 0px 8px;
}

.joption-mode-container {
  display: flex;
  justify-content: space-between;
}

.joption-mode-text {
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #939598;
}
