.journey-details-destination-leg-container {
  padding: 16px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 16px 1fr;
  grid-template-rows: 1fr;
  gap: 0px 8px;
  grid-template-areas: '. .';
}
.journey-details-mobile-destination-leg-container {
  padding: 16px 8px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 16px 1fr;
  grid-template-rows: 1fr;
  gap: 0px 8px;
  grid-template-areas: '. .';
}
.journey-details-destination-stop-container {
  display: flex;
  flex-direction: column;
}
.destination-stop {
  display: flex;
  margin: 0px;
  width: 100%;
}
.get-destination {
  margin: 0px 4px 0px 0px;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
}
.destination-stop-container {
  margin: 0px;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
}
.details-destination-address {
  margin: 4px 0px 0px 0px;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #939598;
}
