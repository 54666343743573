.journey-details-dropoff-leg-container {
  padding: 16px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 80px;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: '. .';
  position: relative;
}
.journey-details-mobile-dropoff-leg-container {
  padding: 16px 8px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 80px;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: '. .';
  position: relative;
}
.journey-details-dropoff-detail {
  display: flex;
  align-items: center;
}
.journey-details-dropoff-stop-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px 0px 0px 8px;
}
.get-dropoff {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin: 0px 4px 0px 0px;
}
.trainid-container {
  display: flex;
  align-items: center;
  color: #ffffff;
  border-radius: 4px;
  padding: 0px 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 14px;
}
.train-stop-name-container {
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  color: #dc241f;
  margin: 0px 4px 0px 4px;
}
.journey-details-dropoff-time {
  display: flex;
  width: min-content;
  position: absolute;
  right: 16px;
  top: 16px;
  border-radius: 4px;
  padding: 0px 4px;
}
.journey-details-mobile-dropoff-time {
  display: flex;
  width: min-content;
  position: absolute;
  right: 8px;
  top: 16px;
  border-radius: 4px;
  padding: 0px 4px;
}
.j-dropoff-time {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  margin: 0px 4px 0px 0px;
}

.j-dropoff-measure {
  font-style: normal;
  font-size: 8px;
  line-height: 14px;
  align-self: flex-end;
  margin: 0px;
}
.dropoffleg-img {
  align-self: flex-start;
}
