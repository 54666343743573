.joption-singlemode-container {
  padding: 14px 16px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.joption-singlemode-mobile-container {
  padding: 14px 4px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: min-content; */
  border-radius: 4px;
}
.joption-singlemode-moreoption {
  display: flex;
  align-items: center;
}
.joption-primary {
  font-size: 14px;
  font-weight: bold;
  align-self: flex-end;
  margin: 0px 4px 0px 0px;
  line-height: 14px;
}
.joption-mobile-primary {
  font-size: 14px;
  font-weight: bold;
  align-self: flex-end;
  margin: 0px 2px 0px 0px;
  line-height: 15px;
}
.joption-secondary {
  font-size: 10px;
  align-self: flex-end;
}
.joption-mobile-secondary {
  font-size: 10px;
  align-self: flex-end;
  line-height: 13px;
}
