.autocomplete-list-container {
  padding: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.autocomplete-lable-list {
  margin: 0px 0px 0px 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.autocomplete-option-label {
  color: #333333;
}
.autocomplete-option-address {
  color: #939598;
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete-input-label {
  display: flex;
}

.autocomplete-img {
  position: absolute;
  z-index: 1;
  transform: translate(6px, 8px);
}
