.jdetail-overview-p-container {
  /* padding: 8px 16px; */
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.jdetail-overview-p-mobile-container {
  /* padding: 8px; */
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.jdetail-overview-p-mobile-container-second {
  /* padding: 8px; */
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jdetail-overview-p-other-container {
  /* padding: 12px 18px; */
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.jdetail-overview-p-other-mobile-container {
  /* padding: 7px 18px; */
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
