.jend-container {
  display: flex;
  flex-direction: column;
  margin: 8px 0px 0px 0px;
  /* border-bottom: 0.5px solid #bbbbbb;
  padding: 0px 0px 16px 0px; */
}
.end-landmark {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  /* color: #ffffff; */
  color: black;
  margin: 0px 0px 4px 0px;
}

.end-fulladdress {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  /* color: #ffffff; */
  color: black;
}

.jend-details {
  display: flex;
}

.jLandmark-end-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.endJourney-img {
  align-self: flex-start;
  margin: 0px 8px 0px 0px;
}
