.joptioncardone-container {
  border: 1px solid;
  border-right-color: #30638e;
  border-radius: 5px 5px;
  border-style: none dashed none none;
  padding: 4px 16px;
  background-color: white;
  cursor: pointer;
  position: relative;
}

.joptioncardone-mobile-container {
  border: 1px solid;
  border-right-color: #30638e;
  border-radius: 5px 5px;
  border-style: none dashed none none;
  padding: 4px 8px;
  background-color: white;
  cursor: pointer;
  position: relative;
}

.joptioncardone-mobile-container:hover .joptioncardone-divider {
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #30638e;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.joptioncardone-container:hover .joptioncardone-divider {
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #30638e;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
