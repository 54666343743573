.price-container {
  display: flex;
  align-items: center;
  width: fit-content;
}

.price-detail-container {
  display: flex;
  align-items: center;
}
.price-currency {
  font-size: 12px;
  margin: 0px 4px 0px 4px;
  color: #939598;
  align-self: flex-end;
}
.price-number {
  font-size: 14px;
  line-height: 16px;
}
