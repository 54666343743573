.journeyoptionparent-desktop-container {
  position: relative;
  top: 44px;
  background-color: #e5e5e5;
  width: 420px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.journeyoptionparent-mobile-container {
  background-color: #e5e5e5;
  padding: 44px 0px 32px 0px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.journeyoptionparent-desktop-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.journeyoptionparent-mobile-container::-webkit-scrollbar {
  display: none;
}
