.journey-detail-car-leg-real-container {
  padding: 16px;
}
.journey-details-mobile-car-leg-real-container {
  padding: 16px 8px;
}
.journey-details-car-leg-container {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 16px 1fr 80px;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: '. .';
  position: relative;
}
.journey-details-mobile-car-leg-container {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 16px 1fr 80px;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: '. .';
  position: relative;
}
.journey-details-car-detail {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  position: relative;
}
.journey-details-car-stop-container {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 8px;
}
.get-car {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin: 0px;
}
.car-container {
  display: flex;
  align-items: center;
  color: #ffffff;
  border-radius: 4px;
  padding: 0px 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 14px;
}
.car-stop-name-container {
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  color: #dc241f;
  margin: 0px 4px 0px 4px;
}
.journey-details-car-time {
  display: flex;
  width: min-content;
  position: absolute;
  right: 4px;
  border-radius: 4px;
  padding: 0px 4px;
}
.journey-details-mobile-car-time {
  display: flex;
  width: min-content;
  position: absolute;
  right: 0px;
  border-radius: 4px;
  padding: 0px 4px;
}
.journey-details-parking-duration-container {
  display: flex;
  align-items: center;
  width: min-content;
  /* position: absolute;
    right: 16px;
    top: 16px; */
  border-radius: 4px;
  padding: 0px 4px;
}
.j-car-time {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  margin: 0px 4px 0px 0px;
}

.j-car-measure {
  font-style: normal;
  font-size: 8px;
  line-height: 14px;
  align-self: flex-end;
  margin: 0px;
}
.j-car-measure-bay {
  font-style: normal;
  font-size: 8px;
  line-height: 16px;
  align-self: flex-end;
  margin: 0px;
}

.car-legs-parking-container {
  display: flex;
  align-items: center;
  margin: 4px 0px 0px 0px;
}
.img-car-icon {
  align-self: flex-start;
}
.car-img {
  align-self: flex-start;
}

.drive_accordion__icon_group_status {
  margin: 0px;
}

.drive-rotate {
  transform: rotate(180deg);
  margin: 0px;
}
.drive-parking-list {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* transition: max-height 0.6s ease; */
}
.drive-show-parking {
  margin: 6px 0px 0px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.drive-show-text {
  margin: 0px 4px 0px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  color: #f16022;
}
.parking-rates {
  /* margin: 4px 0px 0px 0px; */
  font-size: 12px;
}
.drive-leg-navigation {
  margin: 8px 0px 0px 0px;
}
.drive-leg-logo-container {
  border-radius: 0px 0px 8px 8px;
  border: 1px solid #f16022;
  cursor: pointer;
}
.drive-logo-container {
  margin: 7px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.drive-leg-logo {
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 36px;
}
.drive-leg-logo-text {
  margin: 0px 0px 0px 8px;
  font-weight: 600;
  font-size: 14px;
}
.drive-leg-navigate-text {
  border-radius: 8px 8px 0px 0px;
  font-weight: 600;
  width: '100%';
  padding: 4px 0px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  background-color: #f16022;
  color: #ffffff;
  align-items: center;
}
.car-legs-onstreetparking-container {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}
