.journey-detail-bus-container {
  padding: 16px;
  display: grid;
  grid-template-columns: 17px 1fr;
  gap: 0px 8px;
}
.journey-detail-mobile-bus-container {
  padding: 16px 8px;
  display: grid;
  grid-template-columns: 17px 1fr;
  gap: 0px 8px;
}
.timeline-middle-bus {
  position: relative;
  left: 7px;
  background: #ffff;
  border-style: none none none dotted;
  border-color: #30638e;
}
.timeline-middle-sd-bus {
  /* position: relative;
    left: 7px;
    background: #ffff; */
}
.timeline-point-bus {
  position: absolute;
  top: 0;
  z-index: 1;
  transform: translateX(-58%);
  background: #ffff;
}
.timeline-point-sd-bus {
  /* position: absolute;
    top: 0;
    z-index: 1; */
  /* transform: translateX(-58%); */
  background: #ffff;
}
.timeline-component-bus {
  display: grid;
  grid-template-columns: 1fr 96px;
}
.bus-platform {
  display: flex;
  flex-direction: column;
}
.journey-detail-bus-display {
  display: grid;
  grid-template-columns: 13px 1fr;
  gap: 0px 8px;
}
.timeline-middle-stops-bus {
  position: relative;
  top: 3px;
  left: 7px;
  border-style: none none none solid;
  border-width: 3px;
}
.timeline-stop-middle-sd-bus {
  position: relative;
  top: 2px;
  left: 7px;
}
.timeline-stop-middle-sd-bus-fr {
  position: relative;
  top: 2px;
  left: 7px;
}
.bus-platform-detail {
  display: flex;
  flex-wrap: wrap;
  margin: 0px 0px 4px 0px;
}
.timeline-component-sd-bus {
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  background-color: #ffffff;
}
.timeline-component-sd-bus-fr {
  display: grid;
  grid-template-columns: 1fr;
}
.timeline-point-fr-bus {
  position: absolute;
  top: -2px;
  bottom: 1px;
  z-index: 1;
  transform: translateX(-61%);
  /* background: #ffff; */
}
.timeline-point-fr-sd-bus {
  position: absolute;
  top: -2px;
  bottom: 1px;
  z-index: 1;
  transform: translateX(-36%);
}
.timeline-point-fr-sd-bus-ac {
  position: absolute;
  top: -2px;
  left: -21px;
  bottom: 1px;
  z-index: 1;
  transform: translateX(-36%);
}
.j-bus-route-detail {
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 0.5px solid #128110;
  padding: 0px 4px 0px 4px;
  height: 17px;
  margin: 0px 4px 0px 0px;
}
.j-bus-platform-display {
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #939598;
}
.bus-schedule-perform {
  display: flex;
  position: relative;
  top: 3px;
  align-items: center;
  margin: 8px 0px 0px 0px;
  cursor: pointer;
}
.schedule-color-bus {
  font-weight: bold;
  font-size: 10px;
  line-height: 17px;
  color: #f16022;
  margin: 0px 4px 0px 0px;
}
.stopid-name-container-bus {
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
}
.stopid-name-container-bus-fr {
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
}
.exit-img-container {
  display: flex;
  align-items: center;
}
.exit-container {
  margin: 0px 0px 0px 4px;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #939598;
}
.bus-container {
  display: flex;
  flex-direction: column;
}
.bus-container-first {
  font-weight: normal;
  font-size: 8px;
  line-height: 17px;
  align-self: flex-end;
}
.bus-container-second {
  display: flex;
  align-self: flex-end;
}
.bus-container-stop-name {
  display: flex;
  flex-direction: column;
}
.bus-container-second-number {
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  margin: 0px 4px 0px 0px;
}
.bus-container-second-measure {
  font-weight: normal;
  font-size: 8px;
  line-height: 20px;
  align-self: flex-end;
}
.bus-container-second-one {
  display: flex;
  align-self: flex-end;
}
.bus-container-second-one-measure {
  font-weight: normal;
  font-size: 8px;
  line-height: 20px;
}
.bus-container-second-one-number {
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  margin: 0px 4px 0px 0px;
}

.bus_accordion__icon_group_status {
  margin: 0px;
}

.bus-rotate {
  transform: rotate(180deg);
  margin: 0px;
}

.bus-schedule-list {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* transition: max-height 0.6s ease; */
  margin: 0px 0px 8px 0px;
}
.bus-stop-list {
  display: flex;
  flex-direction: column;
  /* transition: max-height 0.6s ease; */
  margin: 8px 0px 8px 0px;
}

.text-bus-hidden {
  color: #ffffff;
  visibility: hidden;
}
.text-bus-nothidden {
  color: #333333;
  visibility: visible;
}
