.drawer-btn-menu {
  display: flex;
  border: none;
  outline: none;
  background-color: transparent;
  color: #333333;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}
.drawer-btn-menu:focus {
  color: #f16022;
}
.drawer-list {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 24px 0px;
  grid-template-areas:
    '.'
    '.'
    '.'
    '.'
    '.'
    '.';
}
.drawer-container {
  width: 210px;
  background-color: #ffffff;
  margin: 44px 0px 0px 0px;
  padding: 16px;
  position: absolute;
  z-index: 4;
  filter: drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.14))
    drop-shadow(0px 6px 30px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.2));
}
