.time-duration-container {
  display: flex;
  padding: 0px 16px 0px 7px;
  justify-content: flex-end;
}
.time-duration-mobile-container {
  display: flex;
  padding: 0px 8px 0px 5px;
  justify-content: flex-end;
}
.duration {
  display: flex;
}
.timeduration-display-container {
  display: flex;
}
.number {
  display: flex;
  align-items: flex-end;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  margin: 0px 4px 0px 0px;
}
.text {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  color: #333333;
  display: flex;
  align-items: flex-end;
}
