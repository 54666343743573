.basic-mobile-container {
  width: 100%;
  height: 360px;
  position: relative;
  border-radius: 8px;
}

.basic-mobile-close-container {
  height: 40px;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
